const mapRecordsToRows = (records, properties) =>
  records.map(row => {
    const result = {};
    row.columns.forEach(item => {
      const columnProperties = properties.filter(
        property => property.name.toUpperCase() === item.name.toUpperCase()
      )[0];

      let value = item?.value ?? '';
      if (columnProperties.datatype === 'DATE')
        value = item?.value?.substr(0, 10);
      result[item.name.toUpperCase()] = value;
    });
    return result;
  });

export default mapRecordsToRows;
